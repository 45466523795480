<template>
  <v-row align="center">
    <v-dialog v-model="dialog" persistent max-width="800">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }} Note</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form lazy-validation autocomplete="off">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="note.title"
                    :error="$v.note.title.$error"
                    outlined
                    dense
                  >
                    <template v-slot:label>
                      Title <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.note.title.$error"
                    >This Field is required</span
                  >
                </v-col>

                <v-col cols="12" md="12">
                  <v-autocomplete
                    v-model="note.user_id"
                    :search-input.sync="userSearch"
                    :items="users"
                    item-text="full_name"
                    item-value="id"
                    hide-details
                    outlined
                    dense
                  >
                    <template v-slot:label>
                      User
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                  <v-select
                    v-model="note.level_id"
                    @change="selectedLevel"
                    :items="levels"
                    item-text="title"
                    item-value="id"
                    hide-details
                    :error="$v.note.level_id.$error"
                    return-object
                    outlined
                    dense
                  >
                    <template v-slot:label>
                      Level <span class="text-danger">*</span>
                    </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.note.level_id.$error"
                    >This Field is required</span
                  >
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  v-if="selectLevel === 'plus-two' || selectLevel === 'school'"
                >
                  <v-select
                    v-model="note.grade_id"
                    @change="getAllSubjects"
                    :items="grades"
                    item-text="title"
                    item-value="id"
                    clearable
                    hide-details
                    :error="$v.note.grade_id.$error"
                    outlined
                    dense
                  >
                    <template v-slot:label>
                      Grade <span class="text-danger">*</span>
                    </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.note.grade_id.$error"
                    >This Field is required</span
                  >
                </v-col>

                <v-col cols="12" md="6" v-if="note.grade_id">
                  <v-select
                    v-model="note.subject_id"
                    :items="subjects"
                    item-text="title"
                    item-value="id"
                    hide-details
                    :error="$v.note.subject_id.$error"
                    outlined
                    dense
                  >
                    <template v-slot:label>
                      Subject <span class="text-danger">*</span>
                    </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.note.subject_id.$error"
                    >This Field is required</span
                  >
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  v-if="selectLevel === 'bachelor' || selectLevel === 'master'"
                >
                  <v-select
                    v-model="note.faculty_id"
                    @change="getAllPrograms"
                    :items="faculties"
                    item-text="title"
                    item-value="id"
                    clearable
                    hide-details
                    :error="$v.note.faculty_id.$error"
                    outlined
                    dense
                  >
                    <template v-slot:label>
                      Faculty <span class="text-danger">*</span>
                    </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.note.faculty_id.$error"
                    >This Field is required</span
                  >
                </v-col>

                <v-col cols="12" md="6" v-if="note.faculty_id">
                  <v-select
                    v-model="note.program_id"
                    @change="getAllSemester"
                    :items="programs"
                    item-text="title"
                    item-value="id"
                    clearable
                    hide-details
                    :error="$v.note.program_id.$error"
                    outlined
                    dense
                  >
                    <template v-slot:label>
                      Program <span class="text-danger">*</span>
                    </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.note.program_id.$error"
                    >This Field is required</span
                  >
                </v-col>

                <v-col cols="12" md="12" v-if="note.program_id">
                  <v-select
                    v-model="note.semester_id"
                    :items="semesters"
                    item-text="title"
                    item-value="id"
                    clearable
                    hide-details
                    :error="$v.note.semester_id.$error"
                    outlined
                    dense
                  >
                    <template v-slot:label>
                      Semester/Year <span class="text-danger">*</span>
                    </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.note.semester_id.$error"
                    >This Field is required</span
                  >
                </v-col>

                <v-col cols="12" md="12">
                  <v-file-input
                    v-model="note.files"
                    prepend-icon=""
                    prepend-inner-icon="mdi-file"
                    accept="application/pdf"
                    :error="$v.note.files.$error"
                    outlined
                    dense
                  >
                    <template v-slot:label>
                      File <span class="text-danger">*</span>
                    </template>
                  </v-file-input>
                  <span class="text-danger" v-if="$v.note.files.$error"
                    >This Field is required</span
                  >
                </v-col>

                <v-col cols="12" md="12">
                  <v-file-input
                    v-model="note.imageFile"
                    @change="previewImage"
                    prepend-icon=""
                    prepend-inner-icon="mdi-file"
                    accept="image/*"
                    :error="$v.note.imageFile.$error"
                    outlined
                    dense
                  >
                    <template v-slot:label>
                      Image <span class="text-danger">*</span>
                    </template>
                  </v-file-input>
                  <span class="text-danger" v-if="$v.note.imageFile.$error"
                    >This Field is required</span
                  >
                </v-col>
                <v-col cols="12" md="12">
                  <v-img
                    class="w-100 max-h-300px"
                    contain
                    :src="defaultImage"
                  ></v-img>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" dark @click="createOrUpdate">
            {{ edit ? "Update" : "Save" }}
          </v-btn>
          <v-btn color="red darken-1" dark @click="closeDialog"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import NoteService from "@/service/note/NoteService";
import FacultyService from "@/service/faculty/FacultyService";
import GradeService from "@/service/grade/GradeService";
import SubjectService from "@/service/subject/SubjectService";
import ProgramService from "@/service/program/ProgramService";
import SemesterService from "@/service/semester-and-year/SemesterService";
import UserService from "@/service/user/UserService";
import LevelService from "@/service/level/LevelService";

const note = new NoteService();
const level = new LevelService();
const grade = new GradeService();
const subject = new SubjectService();
const faculty = new FacultyService();
const program = new ProgramService();
const semester = new SemesterService();
const user = new UserService();

export default {
  name: "CreateAndUpdate",
  data() {
    return {
      dialog: false,
      edit: false,
      users: [],
      selectLevel: {},
      levels: [],
      grades: [],
      subjects: [],
      faculties: [],
      programs: [],
      semesters: [],
      errors: [],
      userSearch: null,
      note: {
        user_id: "",
        admin_user_id: "",
        level_id: "",
        grade_id: "",
        subject_id: "",
        faculty_id: "",
        program_id: "",
        semester_id: "",
        files: null,
        imageFile: null,
        upload_by: "admin",
        status: "approved"
      },
      defaultImage: require("@/assets/images/no-image.png")
    };
  },
  validations: {
    note: {
      title: { required },
      level_id: { required },
      grade_id: {
        required: requiredIf(function() {
          return this.selectLevel === "plus-two" || this.selectLevel === "+2";
        })
      },
      subject_id: {
        required: requiredIf(function() {
          return this.note.grade_id;
        })
      },
      faculty_id: {
        required: requiredIf(function() {
          return (
            this.selectLevel === "bachelor" || this.selectLevel === "master"
          );
        })
      },
      program_id: {
        required: requiredIf(function() {
          return this.note.faculty_id;
        })
      },
      semester_id: {
        required: requiredIf(function() {
          return this.note.program_id;
        })
      },
      files: { required },
      imageFile: { required }
    }
  },
  mounted() {
    this.getAllLevel();
  },
  watch: {
    userSearch(val) {
      if (this.isLoading) return;
      this.isLoading = true;
      if (val != null) {
        user
          .all(val)
          .then(response => {
            this.users = response.data.data;
          })
          .catch(() => {
            // console.log(err)
          });
      }
      this.isLoading = false;
    }
  },
  computed: {
    title() {
      if (this.edit) {
        return "Edit";
      } else {
        return "Create";
      }
    }
  },
  methods: {
    selectedLevel() {
      let level = this.note.level_id;
      this.note.level_id = level.id;
      this.selectLevel = level.slug;
      if (this.selectLevel === "plus-two" || this.selectLevel === "school") {
        this.getAllGrades();
      } else {
        this.getAllFaculties();
      }
    },
    getAllLevel() {
      level
        .all()
        .then(response => {
          this.levels = response.data.data;
        })
        .catch(() => {});
    },
    getAllGrades() {
      grade
        .getByLevel(this.note.level_id)
        .then(response => {
          this.grades = response.data.data;
        })
        .catch(() => {});
    },
    getAllSubjects() {
      subject
        .getByGrade(this.note.grade_id)
        .then(response => {
          this.subjects = response.data.subjects;
        })
        .catch(() => {});
    },
    getAllFaculties() {
      faculty
        .getByLevel(this.note.level_id)
        .then(response => {
          this.faculties = response.data.data;
        })
        .catch(() => {});
    },
    getAllPrograms() {
      program
        .getByFaculty(this.note.faculty_id)
        .then(response => {
          this.programs = response.data.programs;
        })
        .catch(() => {});
    },
    getAllSemester() {
      semester
        .getByProgram(this.note.program_id)
        .then(response => {
          this.semesters = response.data.semesters;
        })
        .catch(() => {});
    },
    openDialog() {
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editDialog(note) {
      this.dialog = true;
      this.edit = true;
      this.resetForm();
      this.getAllLevel();
      this.note = note;
      if (this.note.image_path) {
        this.defaultImage = this.note.image_path.original;
      }
      if (this.note.grade_id && this.note.subject_id) {
        this.getAllGrades();
        this.getAllSubjects();
      } else {
        this.getAllFaculties();
        this.getAllPrograms();
        this.getAllSemester();
      }
      this.selectLevel = this.note.level.replace(" ", "-").toLowerCase();
    },
    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },
    resetForm() {
      this.$v.$reset();
      this.selectLevel = {};
      this.users = [];
      this.defaultImage = require("@/assets/images/no-image.png");
      this.note = {
        user_id: "",
        level_id: "",
        grade_id: "",
        subject_id: "",
        faculty_id: "",
        program_id: "",
        semester_id: "",
        files: null,
        imageFile: null,
        upload_by: "admin",
        status: "approved"
      };
    },
    previewImage() {
      if (this.note.imageFile != null) {
        this.defaultImage = URL.createObjectURL(this.note.imageFile);
      } else {
        this.defaultImage = require("@/assets/images/no-image.png");
      }
    },
    setFormData() {
      let formData = new FormData();
      for (let key in this.note) {
        if (this.note[key]) {
          formData.append(key, this.note[key]);
        }
      }
      return formData;
    },
    createOrUpdate() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 6000);
      } else {
        let formData = this.setFormData();
        if (this.edit) {
          note
            .update(this.note.id, formData)
            .then(response => {
              if (response.data.status === "OK") {
                this.$snotify.success("Note updated successfully");
                this.$emit("refresh");
                this.closeDialog();
              }
            })
            .catch(error => {
              this.errors = error.response.data.errors;
            });
        } else {
          note
            .store(formData)
            .then(response => {
              if (response.data.status === "OK") {
                this.$snotify.success("Note created successfully");
                this.$emit("refresh");
                this.closeDialog();
              }
            })
            .catch(error => {
              this.errors = error.response.data.errors;
            });
        }
      }
    }
  }
};
</script>
