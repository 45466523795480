<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Note</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Note
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <a
                    @click="createNote()"
                    class="btn btn-primary font-weight-bolder font-size-sm"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-white"></span>
                    Add Note
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Search by name, phone and mobile"
                    v-model="search.user_info"
                    v-on:keyup.enter="getAllNotes"
                    @input="search.user_info = $event !== null ? $event : ''"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    label="Title"
                    v-model="search.title"
                    v-on:keyup.enter="getAllNotes"
                    @input="search.title = $event !== null ? $event : ''"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-autocomplete
                    label="Level"
                    v-model="search.level_id"
                    @change="selectedLevel"
                    v-on:keyup.enter="getAllNotes"
                    @input="search.level_id = $event !== null ? $event : ''"
                    :items="levels"
                    item-text="title"
                    item-value="id"
                    return-object
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                  v-if="
                    selectLevel.slug === 'plus-two' ||
                      selectLevel.slug === 'school'
                  "
                >
                  <v-autocomplete
                    label="Grade"
                    v-model="search.grade_id"
                    v-on:keyup.enter="getAllNotes"
                    @input="search.grade_id = $event !== null ? $event : ''"
                    :items="grades"
                    item-text="title"
                    item-value="id"
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="3" v-if="search.grade_id">
                  <v-autocomplete
                    label="Subject"
                    v-model="search.subject_id"
                    v-on:keyup.enter="getAllNotes"
                    @input="search.subject_id = $event !== null ? $event : ''"
                    :items="subjects"
                    item-text="title"
                    item-value="id"
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                  v-if="
                    selectLevel.slug === 'bachelor' ||
                      selectLevel.slug === 'master'
                  "
                >
                  <v-autocomplete
                    label="Faculty"
                    v-model="search.faculty_id"
                    @change="getAllPrograms"
                    v-on:keyup.enter="getAllNotes"
                    @input="search.faculty_id = $event !== null ? $event : ''"
                    :items="faculties"
                    item-text="title"
                    item-value="id"
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="3" v-if="search.faculty_id">
                  <v-autocomplete
                    label="Program"
                    v-model="search.program_id"
                    @change="getAllSemester"
                    v-on:keyup.enter="getAllNotes"
                    @input="search.program_id = $event !== null ? $event : ''"
                    :items="programs"
                    item-text="title"
                    item-value="id"
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="3" v-if="search.program_id">
                  <v-autocomplete
                    label="Semester/Year"
                    v-model="search.semester_id"
                    v-on:keyup.enter="getAllNotes"
                    @input="search.semester_id = $event !== null ? $event : ''"
                    :items="semesters"
                    item-text="title"
                    item-value="id"
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    v-model="search.status"
                    v-on:keyup.enter="getAllNotes"
                    @input="search.status = $event !== null ? $event : ''"
                    label="Status"
                    :items="status"
                    item-text="name"
                    item-value="value"
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="12" :md="number">
                  <v-btn
                    class="btn btn-primary w-35 float-right"
                    :loading="loading"
                    @click="searchNote"
                    dark
                  >
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <template v-if="loading && notes.length > 0">
              <v-skeleton-loader
                :key="i"
                type="table-heading, list-item-two-line, image, table-tfoot"
                v-for="i in notes.length"
              ></v-skeleton-loader>
            </template>

            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr class="text-left">
                    <th class="px-3"><strong>Title</strong></th>
                    <th class="px-3"><strong>Level</strong></th>
                    <th class="px-3"><strong>Grade</strong></th>
                    <th class="px-3"><strong>Subject</strong></th>
                    <th class="px-3"><strong>Faculty</strong></th>
                    <th class="px-3"><strong>Program</strong></th>
                    <th class="px-3"><strong>Semester/Year</strong></th>
                    <th class="px-3"><strong>Upload By</strong></th>
                    <th class="px-3"><strong>Status</strong></th>
                    <th class="pr-3 text-center"><strong>Action</strong></th>
                  </tr>
                </thead>
                <template v-if="notes.length > 0">
                  <tbody>
                    <tr v-for="(item, index) in notes" :key="index">
                      <td class="px-2">
                        <a
                          @click="editNote(item)"
                          class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >
                          <i class="fa fa-sort"></i>

                          <span class="ml-3"> {{ item.title }}</span>
                        </a>
                      </td>

                      <td class="px-2">
                        {{ item.level ? item.level : "N/A" }}
                      </td>

                      <td class="px-2">
                        {{ item.grade ? item.grade : "N/A" }}
                      </td>

                      <td class="px-2">
                        {{ item.subject ? item.subject : "N/A" }}
                      </td>

                      <td class="px-2">
                        {{ item.faculty ? item.faculty : "N/A" }}
                      </td>

                      <td class="px-2">
                        {{ item.program ? item.program : "N/A" }}
                      </td>

                      <td class="px-2">
                        {{ item.semester ? item.semester : "N/A" }}
                      </td>

                      <td class="px-2">
                        <b>Upload By: </b>{{ item.upload_by_text }} <br />
                        <b>User: </b>{{ item.user ? item.user : "N/A" }}
                      </td>

                      <td class="px-2">
                        <span
                          class="badge text-lg"
                          v-bind:class="{
                            'badge-success': item.status == 'approved',
                            'badge-danger': item.status == 'rejected',
                            'badge-warning': item.status == 'pending'
                          }"
                        >
                          {{ item.status_text }}
                        </span>
                      </td>

                      <td class="pr-0 px-2 text-center">
                        <template>
                          <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                            style="padding: 0"
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-175px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="openFile(item)" class="navi-link">
                                  <span class="navi-icon">
                                    <v-icon color="blue darken-2"
                                      >fas fa-file</v-icon
                                    >
                                  </span>
                                  <span class="navi-text">Preview</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="editNote(item)" class="navi-link">
                                  <span class="navi-icon">
                                    <v-icon color="blue darken-2"
                                      >fas fa-edit</v-icon
                                    >
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a
                                  @click="deleteNote(item.id)"
                                  class="navi-link"
                                >
                                  <span class="navi-icon">
                                    <v-icon color="red">fas fa-trash</v-icon>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">
                      <h5>No Data Found</h5>
                    </td>
                  </tr>
                </template>
              </table>
              <b-pagination
                v-if="notes.length > 0"
                class="pull-right mt-7"
                @input="getAllNotes"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>

          <create-and-update
            ref="create-and-update"
            @refresh="getAllNotes"
          ></create-and-update>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import CreateAndUpdate from "@/view/pages/note/CreateAndUpdate.vue";
import NoteService from "@/service/note/NoteService";
import LevelService from "@/service/level/LevelService";
import GradeService from "@/service/grade/GradeService";
import SubjectService from "@/service/subject/SubjectService";
import FacultyService from "@/service/faculty/FacultyService";
import ProgramService from "@/service/program/ProgramService";
import SemesterService from "@/service/semester-and-year/SemesterService";

const level = new LevelService();
const grade = new GradeService();
const subject = new SubjectService();
const faculty = new FacultyService();
const program = new ProgramService();
const semester = new SemesterService();
const note = new NoteService();

export default {
  components: {
    CreateAndUpdate
  },
  data() {
    return {
      drag: false,
      loading: false,
      isSorting: false,
      total: null,
      perPage: null,
      page: null,
      selectLevel: {},
      levels: [],
      grades: [],
      subjects: [],
      faculties: [],
      programs: [],
      semesters: [],
      notes: [],
      status: [
        { name: "Pending", value: "pending" },
        { name: "Approved", value: "approved" },
        { name: "Not Approved", value: "not_approved" }
      ],
      search: {
        title: "",
        user_info: "",
        level_id: "",
        grade_id: "",
        subject_id: "",
        faculty_id: "",
        program_id: "",
        semester_id: "",
        status: ""
      }
    };
  },
  mounted() {
    this.getAllNotes();
    this.getAllLevel();
  },
  computed: {
    number() {
      if (
        (this.selectLevel.slug === "plus-two" ||
          this.selectLevel.slug === "school") &&
        !this.search.grade_id
      ) {
        return 9;
      }
      if (
        (this.selectLevel.slug === "plus-two" ||
          this.selectLevel.slug === "school") &&
        this.search.grade_id
      ) {
        return 6;
      }
      if (
        (this.selectLevel.slug === "bachelor" ||
          this.selectLevel.slug === "master") &&
        !this.search.faculty_id
      ) {
        return 9;
      }
      if (
        (this.selectLevel.slug === "bachelor" ||
          this.selectLevel.slug === "master") &&
        this.search.faculty_id &&
        !this.search.program_id
      ) {
        return 6;
      }
      if (this.search.faculty_id && this.search.program_id) {
        return 3;
      }
      return 12;
    }
  },
  methods: {
    createNote() {
      this.$refs["create-and-update"].openDialog();
    },
    editNote(note) {
      this.$refs["create-and-update"].editDialog(note);
    },
    openFile(item) {
      if (item && item.file_path) {
        window.open(item.file_path.original);
      } else {
        this.$snotify("File Not Found!!");
      }
    },
    selectedLevel() {
      this.selectLevel = this.search.level_id;
      this.search.level_id = this.selectLevel.id;
      this.search.grade_id = "";
      this.search.subject_id = "";
      this.search.faculty_id = "";
      this.search.program_id = "";
      this.search.semester_id = "";
      if (
        this.selectLevel.slug === "plus-two" ||
        this.selectLevel.slug === "school"
      ) {
        this.getAllGrades();
      } else {
        this.getAllFaculties();
      }
    },
    getAllLevel() {
      level
        .all()
        .then(response => {
          this.levels = response.data.data;
        })
        .catch(() => {});
    },
    getAllGrades() {
      grade
        .getByLevel(this.search.level_id)
        .then(response => {
          this.grades = response.data.data;
        })
        .catch(() => {});
    },
    getAllSubjects() {
      subject
        .getByGrade(this.search.grade_id)
        .then(response => {
          this.subjects = response.data.data;
        })
        .catch(() => {});
    },
    getAllFaculties() {
      faculty
        .getByLevel(this.search.level_id)
        .then(response => {
          this.faculties = response.data.data;
        })
        .catch(() => {});
    },
    getAllPrograms() {
      program
        .getByFaculty(this.search.faculty_id)
        .then(response => {
          this.programs = response.data.programs;
        })
        .catch(() => {});
    },
    getAllSemester() {
      semester
        .getByProgram(this.search.program_id)
        .then(response => {
          this.semesters = response.data.semesters;
        })
        .catch(() => {});
    },
    getAllNotes() {
      this.loading = true;
      note
        .paginate(this.search, this.page)
        .then(response => {
          this.notes = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    searchNote() {
      this.getAllNotes();
    },
    deleteNote(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            note
              .delete(item)
              .then(response => {
                if (response.data.status === "OK") {
                  this.getAllNotes();
                  this.$snotify.success("Note Deleted Successfully ");
                }
              })
              .catch(() => {
                this.$snotify.error("Oops something went wrong");
              });
          }
        }
      });
    }
  }
};
</script>
