import ApiService from "../../core/services/api.service";

const apiService = ApiService;

export default class LevelService {
  #api = null;

  constructor() {
    this.#api = `admin/level`;
  }

  paginate(data, index = null) {
    let url = `${this.#api}`;
    if (index != null) url = `${url}?page=${index}`;
    let param = {
      params: data
    };
    return apiService.query(url, param);
  }

  all() {
    let url = `${this.#api}/get/all`;
    return apiService.get(url);
  }

  sort(data = []) {
    let url = `${this.#api}/sort`;
    return apiService.post(url, data);
  }

  store(data) {
    let url = `${this.#api}`;
    return apiService.post(url, data);
  }

  show(id) {
    let url = `${this.#api}/${id}`;
    return apiService.get(url);
  }

  update(id, data) {
    let url = `${this.#api}/${id}`;
    return apiService.put(url, data);
  }

  delete(id) {
    let url = `${this.#api}/${id}`;
    return apiService.delete(url);
  }
}
